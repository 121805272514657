import * as React from "react";

import { ErrorModal } from "../../components/atoms/ErrorModal";
import { useError } from "./hooks";

export const ErrorScreen: React.FC<{
	closeModal: () => void;
	message?: string | null;
}> = ({ closeModal, message = "エラーが発生しました。" }) => {
	return <ErrorModal onClose={() => closeModal()} message={message} />;
};

const STATUS_CODE_TO_MESSAGE: { [key: number]: string } = {
	422: "エラーが発生しました"
};

export const ErrorMask: React.FC = () => {
	const { error, removeError } = useError();
	return (
		<>
			{error && (
				<ErrorScreen
					closeModal={removeError}
					message={
						error.message ||
						(error.statusCode && STATUS_CODE_TO_MESSAGE[error.statusCode]) ||
						undefined
					}
				/>
			)}
		</>
	);
};
