import { useCallback, useContext, useEffect, useState } from "react";

import { PlayerContext } from "../../../providers/PlayerProvider/PlayerProvider";

export const useTemplateHSF = () => {
	const { track } = useContext(PlayerContext);
	const [isSideMenuPresent, setIsSideMenuPresent] = useState<boolean>(false);

	useEffect(() => {
		setIsSideMenuPresent(!(window.innerWidth < 1288));
	}, []);

	const hideSideMenu = useCallback(() => {
		if (isSideMenuPresent && window.innerWidth < 1288) {
			setIsSideMenuPresent(false);
		}
		if (!isSideMenuPresent && window.innerWidth >= 1288) {
			setIsSideMenuPresent(true);
		}
	}, [isSideMenuPresent]);

	useEffect(() => {
		window.addEventListener("resize", hideSideMenu);
		return () => window.removeEventListener("resize", hideSideMenu);
	}, [hideSideMenu]);

	const toggleSideMenu = useCallback(() => {
		setIsSideMenuPresent((isPresent) => !isPresent);
	}, []);

	return { track, isSideMenuPresent, toggleSideMenu, hideSideMenu };
};
