import axios from "axios";

export const apiClient = axios.create({
	baseURL: process.env.NEXT_PUBLIC_BASE_API_URL,
	responseType: "json",
	headers: {
		"Content-Type": "application/json"
	}
});

export const swrFetcher = (url: string) => apiClient.get(url);
