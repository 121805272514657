import tw from "twin.macro";

export enum FontType {
	EXSMALL = "exsmall_normal",
	EXSMALL_BOLD = "exsmall_bold",
	SMALL = "small_normal",
	SMALL_BOLD = "small_bold",
	MAIN = "main_normal",
	MAIN_BOLD = "main_bold",
	LARGE = "large_normal",
	LARGE_BOLD = "large_bold",
	EXLARGE = "exlarge_normal",
	EXLARGE_BOLD = "exlarge_bold",
	TITLE = "title"
}

export const FontStyles = {
	exsmall_normal: tw`font-poppins font-normal text-xs`, //400 0.75rem
	exsmall_bold: tw`font-poppins font-semibold text-xs`, //700 0.75rem
	small_normal: tw`font-poppins font-normal text-sm`, //400 0.75rem
	small_bold: tw`font-poppins font-medium text-sm`, //700 0.75rem
	main_normal: tw`font-poppins font-medium text-base`, //500 1rem
	main_bold: tw`font-poppins font-semibold text-base`, //700 1rem
	large_normal: tw`font-poppins font-medium text-xl`, //500 1.25rem
	large_bold: tw`font-poppins font-semibold text-xl`, //700 1.25rem
	exlarge_normal: tw`font-poppins font-medium text-2xl`,
	exlarge_bold: tw`font-poppins font-bold text-2xl`,
	title: tw`font-poppins font-bold text-3xl` //700 1.875rem
};

export const applyFont = (fontType: FontType) => FontStyles[fontType];
