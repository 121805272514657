import { Text } from "../../components/atoms/Text";
import { Color } from "../../constants/Color";
import { FontType } from "../../constants/Fonts";
import { useWeb3 } from "../Web3Provider/Web3Provider";
import { useAuth } from "./hooks";
import {
	StyledBackDrop,
	StyledButton,
	StyledModalContainer,
	StyledOptionContainer,
	StyledOptionLabelWrapper,
	StyledTitleWrapper
} from "./style";

export const SignInModal: React.FC = () => {
	const { auth0SignIn, isSignInModalVisible, toggleSignInModal } = useAuth();
	const { requestToConnect } = useWeb3();
	return (
		<>
			{isSignInModalVisible && (
				<>
					<StyledBackDrop onClick={toggleSignInModal}>
						<StyledModalContainer>
							<StyledTitleWrapper>
								<Text fontType={FontType.MAIN_BOLD}>サインイン方法を選択</Text>
							</StyledTitleWrapper>
							<StyledOptionContainer>
								<StyledOptionLabelWrapper>
									<Text fontType={FontType.SMALL} color={Color.MAIN}>
										MATIC決済のみ対応
									</Text>
								</StyledOptionLabelWrapper>
								<StyledButton
									onClick={(e) => {
										e.stopPropagation();
										requestToConnect();
									}}
								>
									<Text fontType={FontType.SMALL_BOLD} color={Color.BG}>
										Metamaskと接続
									</Text>
								</StyledButton>
							</StyledOptionContainer>
							<StyledOptionContainer>
								<StyledOptionLabelWrapper>
									<Text fontType={FontType.SMALL} color={Color.MAIN}>
										現金決済のみ対応
									</Text>
								</StyledOptionLabelWrapper>
								<StyledButton
									onClick={(e) => {
										e.stopPropagation();
										auth0SignIn();
									}}
								>
									<Text fontType={FontType.SMALL_BOLD} color={Color.BG}>
										メールアドレス または Google連携
									</Text>
								</StyledButton>
							</StyledOptionContainer>
							<Text fontType={FontType.EXSMALL}>
								※
								Metamaskと接続するには、PCの場合は拡張機能の追加、スマートフォン、タブレットの場合はアプリインストールが必要です。Safariではお使い頂けません。
							</Text>
						</StyledModalContainer>
					</StyledBackDrop>
				</>
			)}
		</>
	);
};
