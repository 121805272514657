import { AxiosError } from "axios";
import { useRouter } from "next/router";
import React, { ReactNode, createContext, useCallback, useState } from "react";

import { PageName } from "../../constants/PageName";

export class GlobalError extends Error {
	public statusCode?: number;
	constructor(message?: string, statusCode?: number) {
		super(message);
		this.statusCode = statusCode;
	}
}

interface ErrorInfo {
	url?: string;
	message?: string;
	statusCode?: number;
}

interface SetError {
	(info: ErrorInfo): void;
	(infoList: ErrorInfo[]): void;
	(info: ErrorInfo, callback: () => void): void;
	(error: AxiosError, info: ErrorInfo, errorPage?: string): void;
}

export interface ErrorContextValueType {
	error?: GlobalError | null;
	setError: SetError;
	removeError: () => void;
}

export const ErrorContext = createContext({} as ErrorContextValueType);

const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [error, setGlobalError] = useState<GlobalError | null>(null);
	const router = useRouter();

	const setError: SetError = useCallback(
		(
			arg1: ErrorInfo | ErrorInfo[] | AxiosError,
			arg2?: ErrorInfo | (() => void),
			arg3?: string
		) => {
			// 2引数版 コールバック関数あり
			// エラーモーダルを出す
			// エラーモーダルを閉じるときにコールバック関数を呼ぶ
			if (arg1 && arg2 && typeof arg2 === "function") {
				const info = arg1 as ErrorInfo;
				setGlobalError(
					(prevError) =>
						prevError || new GlobalError(info.message, info.statusCode)
				);
				const callback = arg2 as () => void;
				setOnErrorRemoved((prevCallback) => prevCallback || callback);
			}
			// 2引数版 コールバック関数なし
			// 状態に応じて以下を選択
			// * Sentryに送信
			// * 適切なエラーメッセージを表示
			// * システムエラーへ遷移
			else if (arg1 && arg2) {
				const error = arg1 as AxiosError;
				const errorPage = arg3;

				// responseがない場合はネットワークエラーにする
				if (!error.response) {
					setGlobalError(
						(prevError) =>
							prevError ||
							new GlobalError(
								"ネットワーク接続が確認できません。\n接続状況を確認後、リトライしてください。"
							)
					);
				}
				// code が ECONNABORTED の時
				else if (error.code === "ECONNABORTED") {
					setGlobalError(
						(prevError) =>
							prevError ||
							new GlobalError(
								"通信状況が悪くなっております。通信環境を確認後、リトライしてください。"
							)
					);
				}
				// それ以外はシステムエラーにする
				else {
					router.push(errorPage || `/${PageName.SYSTEM_ERROR}`);
				}
			}
			// 1引数版
			// エラーモーダルを出す
			else if (Array.isArray(arg1)) {
				const infoList = arg1;
				const message = infoList.map((e) => e.message).join("\n");
				setGlobalError((prevError) => prevError || new GlobalError(message));
			} else {
				const info = arg1 as ErrorInfo;
				setGlobalError(
					(prevError) =>
						prevError || new GlobalError(info.message, info.statusCode)
				);
			}
		},
		[router]
	);

	const [onErrorRemoved, setOnErrorRemoved] = useState<(() => void) | null>(
		null
	);

	const removeError = useCallback(() => {
		setGlobalError(null);
		onErrorRemoved?.();
		setOnErrorRemoved(null);
	}, [onErrorRemoved]);

	const contextValue = {
		error,
		setError: setError,
		removeError: useCallback(() => removeError(), [removeError])
	};

	return (
		<ErrorContext.Provider value={contextValue}>
			{children}
		</ErrorContext.Provider>
	);
};

export default ErrorProvider;
