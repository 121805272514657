import React, { ReactNode } from "react";

import { ButtonType } from "../../../constants/Button";
import { StyledButton } from "./style";

export interface ButtonProps {
	buttonType?: ButtonType;
	children: ReactNode;
	disabled?: boolean;
	onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
	buttonType = ButtonType.NORMAL,
	children,
	disabled = false,
	onClick
}) => {
	return (
		<StyledButton buttonType={buttonType} disabled={disabled} onClick={onClick}>
			{children}
		</StyledButton>
	);
};
