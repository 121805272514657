import { useMediaQuery } from "react-responsive";

export const useMediaSize = () => {
	const isExSm = useMediaQuery({ query: "(max-width: 640px)" });
	const isSm = useMediaQuery({ query: "(min-width: 640px)" });
	const isMd = useMediaQuery({ query: "(min-width: 976px)" });
	const isLg = useMediaQuery({ query: "(min-width: 1288px)" });
	const isXl = useMediaQuery({ query: "(min-width: 1600px)" });

	return { isExSm, isSm, isMd, isLg, isXl };
};
