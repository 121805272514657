import { Auth0Provider } from "@auth0/auth0-react";
import type { AppProps } from "next/app";

import "../../styles/globals.css";
import { TemplateHSF } from "../components/templates/TemplateHSF";
import { SignInModal } from "../providers/AuthProvider";
import AuthProvider from "../providers/AuthProvider/AuthProvider";
import LoadingIndicator from "../providers/LoadingProvider";
import LoadingProvider from "../providers/LoadingProvider/LoadingProvider";
import PlayerProvider from "../providers/PlayerProvider/PlayerProvider";
import { Web3Provider } from "../providers/Web3Provider/Web3Provider";
import { ContractOption } from "../providers/Web3Provider/hooks";
import ErrorProvider from "../providers/ErrorProvider/ErrorProvider";
import { ErrorMask } from "../providers/ErrorProvider";
import NotificationProvider from "../providers/NotificationProvider/NotificationProvider";

const contractOptions: ContractOption[] = [];

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<ErrorProvider>
			<Web3Provider
				chainId={parseInt(process.env.NEXT_PUBLIC_CHAIN_ID!)}
				contractOptions={contractOptions}
			>
				<Auth0Provider
					domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string}
					clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
					redirectUri={process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI as string}
				>
					<AuthProvider>
						<NotificationProvider>
							<LoadingProvider>
								<PlayerProvider>
									<TemplateHSF>
										<Component {...pageProps} />
									</TemplateHSF>
									<LoadingIndicator />
									<ErrorMask />
									<SignInModal />
								</PlayerProvider>
							</LoadingProvider>
						</NotificationProvider>
					</AuthProvider>
				</Auth0Provider>
			</Web3Provider>
		</ErrorProvider>
	);
}

export default MyApp;
