import styled from "@emotion/styled";
import tw from "twin.macro";

import { Color } from "../../../constants/Color";
import { FontType, applyFont } from "../../../constants/Fonts";

export const StyledHeaderWrapper = styled.div`
	${tw`flex justify-between px-6 h-20 items-center fixed w-screen box-border z-30`}
	background-color: ${Color.BG};
`;

export const StyledBackDrop = styled.div`
	${tw`h-screen w-screen bg-transparent absolute top-0 left-0`}
`;

export const StyledLeftWrapper = styled.div`
	${tw`flex items-center`}
`;

export const StyledLogoWrapper = styled.div`
	${tw`flex items-start ml-4`}
`;

export const StyledImageWrapper = styled.div`
	${tw`relative overflow-hidden w-38 h-8`}
`;

export const StyledAlpha = styled.div`
	color: white;
	${applyFont(FontType.EXSMALL)}
	font-size: 10px;
	border-radius: 5px;
	border: solid white 1px;
	padding: 1px 3px;
	margin-left: 4px;
`;

export const StyledRightWrapper = styled.div`
	${tw`flex`}
`;

export const StyledUploadTextWrapper = styled.div`
	${tw`flex items-center`}
`;

export const StyledTwitterWrapper = styled.a`
	${tw`cursor-pointer ml-6`}
	transition: opacity 0.1s;
	&:hover {
		opacity: 0.8;
	}
`;

export const StyledProfileButton = styled.div`
	${tw`w-10 h-10 rounded-full overflow-hidden relative cursor-pointer`}
`;

export const StyledProfileImageWrapper = styled.div`
	${tw`w-14 h-14 rounded-full overflow-hidden relative`}
`;

export const StyledProfileMenuContainer = styled.div<{ isVisible: boolean }>`
	${tw`w-40 rounded-lg box-border p-4 border border-solid border-white-100 bg-box-bg absolute top-14 right-0 flex flex-col items-center`}
	transform: scale(${({ isVisible }) => (isVisible ? 1 : 0.75)})
	transition: transform 0.1s ease-out;
`;

export const StyledProfileMenuRow = styled.div`
	${tw`flex active:opacity-80 sm:hover:opacity-80 cursor-pointer`}
`;

export const StyledProfileMenuIconWrapper = styled.div`
	${tw`w-5 h-5 mr-3`}
`;

export const StyledSigninButtonWrapper = styled.div`
	${tw`ml-6 relative`}
`;

export const StyledSPSigninButton = styled.div`
	${tw`cursor-pointer`}
	${applyFont(FontType.SMALL_BOLD)}
	color: ${Color.MAIN}
`;

export const StyledWrongChainMessage = styled.div`
	${tw`absolute w-full h-8 items-center bg-main top-20 left-0 flex justify-center`}
`;

export const StyledXWrapper = styled.div`
	${tw`ml-8 flex items-center cursor-pointer`}
`;

export const StyledProfileContainer = styled.div`
	${tw`flex flex-col mb-8 items-center`}
`;

export const StyledUserNameWrapper = styled.div`
	${tw`mt-2 text-center`}
`;

export const StyledMetamaskGuideWrapper = styled.div`
	${tw`text-center`}
`;

export const StyledBellContainer = styled.div`
	${tw`mr-2 sm:mr-4 relative flex items-center cursor-pointer`}
`;

export const StyledNewNotificationCircle = styled.div`
	${tw`w-2 h-2 rounded-full bg-main absolute top-2 right-0`}
`;

export const StyledBellWrapper = styled.div<{ isMobile: boolean }>`
	${tw`-my-1`}
	${({ isMobile }) => (isMobile ? tw`active:opacity-80` : tw`hover:opacity-80`)}
`;
