import React from "react";

import { Color } from "../../../constants/Color";
import { StyledBackDrop, StyledSvg } from "./style";

export const LoadingScreen: React.FC = () => {
	return (
		<StyledBackDrop>
			<StyledSvg viewBox="0 0 120 120" data-test="globalLoading">
				<circle
					cx="60"
					cy="60"
					r="54"
					fill="none"
					stroke={Color.MAIN}
					strokeWidth="3"
					strokeDasharray="339.292"
					strokeDashoffset="135.717"
					strokeLinecap="round"
				/>
			</StyledSvg>
		</StyledBackDrop>
	);
};
