import { Howl } from "howler";
import React, { ReactNode, useCallback, useEffect, useState } from "react";

import { Track } from "../../types/domain/Track/Track";

export type PlayerContextValueType = {
	isPlaying: boolean;
	track?: Track;
	current: number;
	togglePlay: (track: Track) => void;
	changeSeek: (rate: number) => void;
};

export const PlayerContext = React.createContext({} as PlayerContextValueType);

interface PlayerProviderProps {
	children: ReactNode;
}

const PlayerProvider: React.FC<PlayerProviderProps> = ({ children }) => {
	const [prevTrackHash, setPrevTrackHash] = useState<string>("");
	const [track, setTrack] = useState<Track>();
	const [playing, setPlaying] = useState<boolean>(false);
	const [audio, setAudio] = useState<Howl>();
	const [current, setCurrent] = useState<number>(0);
	const [intervalId, setIntervalId] =
		useState<ReturnType<typeof setInterval>>();

	const toggle = useCallback(() => {
		if (playing) {
			audio?.pause();
			setPlaying(false);
		} else {
			audio?.play();
			setPlaying(true);
		}
	}, [audio, playing]);

	const togglePlay = useCallback(
		(track: Track) => {
			if (prevTrackHash === track.trackHash) {
				toggle();
			} else {
				setPlaying(false);
				audio?.unload();
				const newAudio = new Howl({
					src: `https://storage.googleapis.com/cypher-test-track/${track.trackHash}`,
					html5: true,
					onend: () => {
						setTrack(undefined);
						setAudio(undefined);
						setPrevTrackHash("");
					}
				});
				setAudio(newAudio);
				setPlaying(true);
				setTrack(track);
			}
			setPrevTrackHash(track.trackHash);
		},
		[audio, prevTrackHash, toggle]
	);

	const changeSeek = useCallback(
		(rate: number) => {
			if (track && audio) {
				audio.seek(track.duration * rate);
				setCurrent(audio.seek());
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[audio]
	);

	useEffect(() => {
		if (audio && playing) {
			audio.play();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [audio]);

	useEffect(() => {
		if (audio && playing) {
			const timeId = setInterval(() => {
				setCurrent(audio.seek());
			}, 200);
			setIntervalId(timeId);
		}
		if (!playing) {
			if (intervalId) clearInterval(intervalId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playing]);

	const contextValue = {
		isPlaying: playing,
		track,
		current: current,
		togglePlay,
		changeSeek
	};

	return (
		<PlayerContext.Provider value={contextValue}>
			{children}
		</PlayerContext.Provider>
	);
};

export default PlayerProvider;
