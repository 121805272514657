import { useAuth0 } from "@auth0/auth0-react";
import {
	ReactNode,
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useState
} from "react";

import { GetUserByUserIdResponseBody } from "../../api/GetUserByUserId";
import { AuthStatus } from "../../constants/AuthStatus";
import { apiClient } from "../../lib/apiClient";
import { User } from "../../types/domain/User/User";
import { useWeb3 } from "../Web3Provider/Web3Provider";

export interface AuthContextValueType {
	authStatus: AuthStatus;
	userId: string | undefined;
	sub: string | undefined;
	user: User | undefined;
	isLoading: boolean;
	isSignInModalVisible: boolean;
	auth0SignIn: () => void;
	auth0SignOut: () => void;
	toggleSignInModal: () => void;
	refreshUser: () => void;
}

export const AuthContext = createContext({} as AuthContextValueType);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const { isAuthenticated, user, isLoading, loginWithRedirect, logout } =
		useAuth0();
	const { accountAddress } = useWeb3();
	const [userId, setUserId] = useState<string>();
	const [userData, setUserData] = useState<User>();
	const [isSignInModalVisible, setIsSignInModalVisible] =
		useState<boolean>(false);

	const authStatus = useMemo(() => {
		if (isAuthenticated) return AuthStatus.AUTH0;
		else if (accountAddress) return AuthStatus.WALLET;
		else return AuthStatus.UNAUTHENTICATED;
	}, [isAuthenticated, accountAddress]);

	const sub = useMemo(() => user?.sub?.split("|")[1] ?? undefined, [user]);

	useEffect(() => {
		(async () => {
			if (sub != undefined || accountAddress != undefined) {
				const endPoint = `/users/${
					sub ? "auth0/" + sub : "wallet/" + accountAddress
				}`;
				const getRes = await apiClient.get(endPoint);
				if (getRes.data.user) {
					setUserId(getRes.data.user._id);
					setUserData(getRes.data.user);
					return;
				}
				const postRes = await apiClient.post(`/users`, {
					sub: sub ?? undefined,
					userAddress: accountAddress ?? undefined,
					email: userEmail ?? ""
				});
				setUserId(postRes.data.user._id);
				setUserData({
					_id: postRes.data.user._id,
					userName: "",
					email: userEmail ?? "",
					imageHash: "profile",
					description: "",
					notificationPayloads: [],
					collectionIds: [],
					workIds: [],
					createdAt: new Date().getTime()
				});
			}
		})();
	}, [sub, accountAddress]);

	// do not use this variable when newest email address is needed
	const userEmail = useMemo(() => {
		if (user?.email) return user.email;
	}, [user]);

	const auth0SignIn = useCallback(() => {
		loginWithRedirect();
	}, []);

	const auth0SignOut = useCallback(() => {
		logout({ returnTo: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI });
	}, []);

	const toggleSignInModal = useCallback(() => {
		setIsSignInModalVisible((value) => !value);
	}, [isSignInModalVisible]);

	const refreshUser = useCallback(async () => {
		if (!userId) return;
		const res = await apiClient.get<GetUserByUserIdResponseBody>(
			`/users/${userId}`
		);
		setUserData(res.data.user);
	}, [userId]);

	const contextValue = {
		authStatus,
		userId,
		sub,
		user: userData,
		isLoading,
		isSignInModalVisible,
		auth0SignIn,
		auth0SignOut,
		toggleSignInModal,
		refreshUser
	};
	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	);
};

export default AuthProvider;
