import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import { AuthStatus } from "../../../constants/AuthStatus";
import { Color } from "../../../constants/Color";
import { FontType } from "../../../constants/Fonts";
import { PageName } from "../../../constants/PageName";
import { useMediaSize } from "../../../lib/hooks/useMediaSize";
import { Text } from "../../atoms/Text";
import Gem from "../../symbols/gem.svg";
import Help from "../../symbols/help.svg";
import Home from "../../symbols/home.svg";
import Settings from "../../symbols/settings.svg";
import Tutorial from "../../symbols/tutorial.svg";
import { useSideMenu } from "./hooks";
import {
	StyledBackDrop,
	StyledExternalLink,
	StyledFooter,
	StyledLowerSideMenuContainer,
	StyledNavText,
	StyledNavWrapper,
	StyledSideMenuContainer,
	StyledSideMenuWrapper,
	StyledSlash,
	StyledTermsLink
} from "./style";

interface SideMenuProps {
	isPresent: boolean;
	toggleIsPresent: () => void;
}

export const SideMenu: React.FC<SideMenuProps> = ({
	isPresent,
	toggleIsPresent
}) => {
	const { pathname, track, authStatus, userId } = useSideMenu();
	const router = useRouter();
	const { isLg } = useMediaSize();
	const [height, setHeight] = useState(0);

	useEffect(() => {
		setHeight(window.innerHeight);
	}, []);

	return (
		<StyledSideMenuWrapper>
			<StyledBackDrop
				isPresent={isPresent && !isLg}
				height={height}
				onClick={toggleIsPresent}
			/>
			<StyledSideMenuContainer
				isPlayerVisible={!!track}
				isPresent={isPresent}
				height={height}
				isLg={isLg}
			>
				<div>
					<Link href={`/${PageName.HOME}`}>
						<StyledNavWrapper
							selected={pathname === "/"}
							isMobile={isMobile}
							onClick={() => {
								if (!isLg) toggleIsPresent();
							}}
						>
							<Home width={22} height={22} />
							<StyledNavText>ホーム</StyledNavText>
						</StyledNavWrapper>
					</Link>
					{userId && (
						<Link href={`/user/${userId}`}>
							<StyledNavWrapper
								selected={
									pathname === `/user/[userId]` &&
									router.query.userId === userId
								}
								isMobile={isMobile}
								onClick={() => {
									if (!isLg) toggleIsPresent();
								}}
							>
								<Gem width={22} height={22} />
								<StyledNavText>コレクション</StyledNavText>
							</StyledNavWrapper>
						</Link>
					)}
					{authStatus !== AuthStatus.UNAUTHENTICATED && (
						<Link href={`/${PageName.PREFERENCE}`}>
							<StyledNavWrapper
								selected={pathname === `/${PageName.PREFERENCE}`}
								isMobile={isMobile}
								onClick={() => {
									if (!isLg) toggleIsPresent();
								}}
							>
								<Settings width={22} height={22} />
								<StyledNavText>プロフィール設定</StyledNavText>
							</StyledNavWrapper>
						</Link>
					)}
				</div>
				<StyledLowerSideMenuContainer>
					<StyledExternalLink
						href="https://cypher123.notion.site/CYPHER-ba0fa54608c94ac78c98252394b6f0b4"
						target="_blank"
						rel="noopener noreferrer"
						isMobile={isMobile}
					>
						<Tutorial width={22} height={22} />
						<StyledNavText>使い方</StyledNavText>
					</StyledExternalLink>
					<StyledExternalLink
						href="https://www.notion.so/cypher123/FAQ-cc0ed67f6abd4d9fa219b261e3965d8f"
						target="_blank"
						rel="noopener noreferrer"
						isMobile={isMobile}
					>
						<Help width={22} height={22} />
						<StyledNavText>ヘルプ</StyledNavText>
					</StyledExternalLink>
					<StyledFooter>
						<StyledTermsLink
							href="https://cypher123.notion.site/4c0aaa7f6bb041a784bcb36cf7b3714b"
							target="_blank"
							rel="noopner noreferrer"
						>
							利用規約
						</StyledTermsLink>
						<StyledSlash>/</StyledSlash>
						<StyledTermsLink
							href="https://cypher123.notion.site/d04ed264c9ca4d15a2e15aeb9e369b89"
							target="_blank"
							rel="noopner noreferrer"
						>
							プライバシーポリシー
						</StyledTermsLink>
						<br />
						<StyledTermsLink
							href="https://cypher123.notion.site/9d250327be6741b89a0508af76abdd1b"
							target="_blank"
							rel="noopner noreferrer"
						>
							特定商取引法に基づく表記
						</StyledTermsLink>
						<br />
						<br />
						<Text fontType={FontType.EXSMALL} color={Color.WHITE_70}>
							@2022 CYPHER
						</Text>
					</StyledFooter>
				</StyledLowerSideMenuContainer>
			</StyledSideMenuContainer>
		</StyledSideMenuWrapper>
	);
};
