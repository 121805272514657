import styled from "@emotion/styled";
import tw from "twin.macro";

import { Color } from "../../constants/Color";

export const StyledBackDrop = styled.div`
	${tw`fixed flex top-0 right-0 bottom-0 left-0 items-center justify-center`}
	background-color: ${Color.BLACK_50};
	z-index: 100;
`;

export const StyledModalContainer = styled.div`
	${tw`rounded-lg w-80 h-112 flex flex-col items-center px-8 box-border bg-box-bg`}
`;

export const StyledTitleWrapper = styled.div`
	${tw`mt-10 mb-10`}
`;

export const StyledOptionContainer = styled.div`
	${tw`w-64 flex flex-col items-center mb-10`}
`;

export const StyledButton = styled.button`
	${tw`w-64 h-12 rounded-lg bg-main flex justify-center items-center cursor-pointer sm:hover:opacity-80 border-none`}
`;

export const StyledOptionLabelWrapper = styled.div`
	${tw`mb-2`}
`;
