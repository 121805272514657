import * as React from "react";

import {
	StyledBottomBar,
	StyledHamburgerWrapper,
	StyledMiddleBar,
	StyledTopBar
} from "./style";

export interface HamburgerProps {
	onClickHamburger: () => void;
}

export const Hamburger: React.FC<HamburgerProps> = ({ onClickHamburger }) => {
	return (
		<StyledHamburgerWrapper onClick={onClickHamburger}>
			<StyledTopBar />
			<StyledMiddleBar />
			<StyledBottomBar />
		</StyledHamburgerWrapper>
	);
};
