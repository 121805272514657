import React, { ReactNode } from "react";
import { useState } from "react";
import { v4 } from "uuid";

const MAX_LOADING_MILLITIME = 20000;

export type LoadingContextValueType = {
	loadingIDs: string[];
	startLoading: (maxLoadingMillitime?: number) => string;
	finishLoading: (id: string) => void;
};

export const LoadingContext = React.createContext(
	{} as LoadingContextValueType
);

const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [loadingIDs, setLoadingIDs] = useState<string[]>([]);

	const finishLoading = React.useCallback((id: string) => {
		setLoadingIDs((prevLoadingIDs) =>
			prevLoadingIDs.filter((item) => item !== id)
		);
	}, []);

	const startLoading = React.useCallback(
		(maxLoadingMillitime: number = MAX_LOADING_MILLITIME): string => {
			const id = v4();
			setLoadingIDs((prevLoadingIDs) => [...prevLoadingIDs, id]);
			setTimeout(() => finishLoading(id), maxLoadingMillitime);
			return id;
		},
		[finishLoading]
	);

	const contextValue = {
		loadingIDs,
		startLoading: startLoading,
		finishLoading: finishLoading
	};

	return (
		<LoadingContext.Provider value={contextValue}>
			{children}
		</LoadingContext.Provider>
	);
};

export default LoadingProvider;
