import { useCallback, useState } from "react";

export const useHeader = () => {
	const [isProfileMenuVisible, setIsProfileMenuVisible] =
		useState<boolean>(false);

	const toggleProfileMenu = useCallback(() => {
		setIsProfileMenuVisible((isVisible) => !isVisible);
	}, []);

	return {
		isProfileMenuVisible,
		toggleProfileMenu
	};
};
