import React, { ReactNode } from "react";

import { useMediaSize } from "../../../lib/hooks/useMediaSize";
import { Player } from "../../../providers/PlayerProvider";
import { Header } from "../../organisms/Header";
import { SideMenu } from "../../organisms/SideMenu";
import { useTemplateHSF } from "./hooks";
import { StyledAppWrapper, StyledContainer, StyledMainPanel } from "./styles";

interface TemplateHSFProps {
	children: ReactNode;
}

export const TemplateHSF: React.FC<TemplateHSFProps> = ({ children }) => {
	const { track, isSideMenuPresent, toggleSideMenu } = useTemplateHSF();

	const { isLg } = useMediaSize();

	return (
		<StyledAppWrapper>
			<Header onClickHamburger={toggleSideMenu}></Header>
			<SideMenu
				isPresent={isSideMenuPresent}
				toggleIsPresent={toggleSideMenu}
			></SideMenu>
			<StyledMainPanel
				isPlayerVisible={!!track}
				isSideMenuPresent={isSideMenuPresent}
				isLg={isLg}
			>
				<StyledContainer>{children}</StyledContainer>
			</StyledMainPanel>
			{track && <Player />}
		</StyledAppWrapper>
	);
};
