import styled from "@emotion/styled";
import tw from "twin.macro";

import { Color } from "../../../constants/Color";
import { FontType, applyFont } from "../../../constants/Fonts";

export const StyledSideMenuWrapper = styled.div``;

export const StyledBackDrop = styled.div<{
	isPresent: boolean;
	height: number;
}>`
	${tw`fixed bg-black-500 w-screen z-20`}
	overflow: hidden;
	background-attachment: fixed;
	${({ isPresent }) => !isPresent && "display: none;"}
	top: 80px;
	height: ${({ height }) => height - 80 + "px"};
`;

export const StyledSideMenuContainer = styled.div<{
	isPlayerVisible: boolean;
	isPresent: boolean;
	height: number;
	isLg: boolean;
}>`
	${tw`flex flex-col justify-between w-64 sm:w-80 fixed bg-bg z-30`}
	height: ${({ isPlayerVisible, height, isLg }) =>
		isPlayerVisible && isLg ? "calc(100vh - 80px - 72px)" : height - 80 + "px"};
	top: 80px;
	left: 0px;
	transition: left 0.05s ease-out;
	${({ isPresent }) => !isPresent && "left:-320px;"}
`;

export const StyledLowerSideMenuContainer = styled.div``;

export const StyledNavWrapper = styled.div<{
	selected?: boolean;
	isMobile: boolean;
}>`
	${tw`w-56 h-15 flex items-center pl-6 ease-out cursor-pointer rounded-r-xl`}
	${({ selected, isMobile }) =>
		!isMobile
			? selected
				? tw`bg-white-100 hover:bg-white-200`
				: tw`hover:bg-white-100`
			: selected
			? tw`bg-white-100 active:bg-white-200`
			: tw`active:bg-white-100`}
`;

export const StyledNavText = styled.div`
	${tw`ml-4 text-white`}
	${applyFont(FontType.MAIN)}
`;

export const StyledExternalLink = styled.a<{ isMobile: boolean }>`
	${tw`w-56 h-15 flex items-center pl-6 ease-out cursor-pointer rounded-r-xl`}
	text-decoration: none;
	${({ isMobile }) =>
		!isMobile ? tw`hover:bg-white-100` : tw`active:bg-white-100`}
`;

export const StyledFooter = styled.footer`
	${tw`mt-6 ml-6 mb-11`}
`;

export const StyledTermsLink = styled.a`
	${tw`ease-out cursor-pointer`}
	${applyFont(FontType.EXSMALL)}
  color: ${Color.WHITE_70};
	transition: color 0.1s;
	text-decoration: none;
	&:hover {
		color: ${Color.WHITE_100};
	}
`;

export const StyledSlash = styled.span`
	${applyFont(FontType.EXSMALL)}
	color: ${Color.WHITE_70};
	${tw`cursor-default mx-2`}
`;
