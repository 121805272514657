import Image from "next/image";
import Link from "next/link";
import React from "react";

import { AuthStatus } from "../../../constants/AuthStatus";
import { ButtonType } from "../../../constants/Button";
import { Color } from "../../../constants/Color";
import { FontType } from "../../../constants/Fonts";
import { useMediaSize } from "../../../lib/hooks/useMediaSize";
import { useAuth } from "../../../providers/AuthProvider/hooks";
import { Button } from "../../atoms/Button";
import { Hamburger } from "../../atoms/Hamburger";
import { Text } from "../../atoms/Text";
import Signout from "../../symbols/signout.svg";
import Twitter from "../../symbols/twitter.svg";
import X from "../../symbols/x.svg";
import Bell from "../../symbols/bell.svg";
import { useHeader } from "./hooks";
import {
	StyledAlpha,
	StyledBackDrop,
	StyledHeaderWrapper,
	StyledImageWrapper,
	StyledLeftWrapper,
	StyledLogoWrapper,
	StyledMetamaskGuideWrapper,
	StyledProfileButton,
	StyledProfileContainer,
	StyledProfileImageWrapper,
	StyledProfileMenuContainer,
	StyledProfileMenuIconWrapper,
	StyledProfileMenuRow,
	StyledRightWrapper,
	StyledSPSigninButton,
	StyledSigninButtonWrapper,
	StyledTwitterWrapper,
	StyledUploadTextWrapper,
	StyledUserNameWrapper,
	StyledWrongChainMessage,
	StyledXWrapper,
	StyledBellContainer,
	StyledNewNotificationCircle,
	StyledBellWrapper
} from "./style";
import { isMobile } from "react-device-detect";
import { useNotification } from "../../../providers/NotificationProvider/hooks";

export interface HeaderProps {
	onClickHamburger: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onClickHamburger }) => {
	const { isProfileMenuVisible, toggleProfileMenu } = useHeader();
	const { authStatus, isLoading, user, auth0SignOut, toggleSignInModal } =
		useAuth();
	const { isUnreadExists } = useNotification();
	const { isSm, isMd } = useMediaSize();

	return (
		<StyledHeaderWrapper>
			{isProfileMenuVisible && <StyledBackDrop onClick={toggleProfileMenu} />}
			<StyledLeftWrapper>
				<Hamburger onClickHamburger={onClickHamburger}></Hamburger>
				<StyledLogoWrapper>
					<Link href="/">
						<StyledImageWrapper>
							<Image
								src="/cypher-logo.png"
								alt="cypher-logo"
								layout="fill"
								objectFit="contain"
								unoptimized
							/>
						</StyledImageWrapper>
					</Link>
					{isSm && <StyledAlpha>alpha</StyledAlpha>}
				</StyledLogoWrapper>
			</StyledLeftWrapper>
			<StyledRightWrapper>
				{authStatus !== AuthStatus.UNAUTHENTICATED && (
					<Link href="/notifications">
						<StyledBellContainer>
							<StyledBellWrapper isMobile={isMobile}>
								<Bell />
							</StyledBellWrapper>
							{isUnreadExists && <StyledNewNotificationCircle />}
						</StyledBellContainer>
					</Link>
				)}
				<StyledUploadTextWrapper>
					{isMd && (
						<Text fontType={FontType.SMALL_BOLD}>販売のご希望はDMにて </Text>
					)}
					{isSm && (
						<StyledTwitterWrapper
							href="https://twitter.com/cypher123_com"
							target="_blank"
							rel="noopener noreferrer"
						>
							<Twitter fill={"white"} width="32" height="32" />
						</StyledTwitterWrapper>
					)}
				</StyledUploadTextWrapper>
				<StyledSigninButtonWrapper>
					{!isLoading &&
						(authStatus !== AuthStatus.UNAUTHENTICATED ? (
							user?.imageHash && (
								<StyledProfileButton onClick={toggleProfileMenu}>
									<Image
										src={`https://storage.googleapis.com/cypher-test-profile-image/${user.imageHash}`}
										layout="fill"
									/>
								</StyledProfileButton>
							)
						) : isSm ? (
							<Button
								buttonType={ButtonType.IMPORTANT}
								onClick={toggleSignInModal}
							>
								サインイン
							</Button>
						) : (
							<StyledSPSigninButton onClick={toggleSignInModal}>
								サインイン
							</StyledSPSigninButton>
						))}
					{isProfileMenuVisible && user && (
						<StyledProfileMenuContainer isVisible={isProfileMenuVisible}>
							<StyledProfileContainer>
								<StyledProfileImageWrapper>
									<Image
										src={`https://storage.googleapis.com/cypher-test-profile-image/${user.imageHash}`}
										layout="fill"
									/>
								</StyledProfileImageWrapper>
								<StyledUserNameWrapper>
									<Text fontType={FontType.SMALL}>{user.userName}</Text>
								</StyledUserNameWrapper>
							</StyledProfileContainer>
							{authStatus === AuthStatus.AUTH0 ? (
								<StyledProfileMenuRow onClick={auth0SignOut}>
									<StyledProfileMenuIconWrapper>
										<Signout />
									</StyledProfileMenuIconWrapper>
									<Text fontType={FontType.SMALL}>サインアウト</Text>
								</StyledProfileMenuRow>
							) : (
								<StyledMetamaskGuideWrapper>
									<Text fontType={FontType.EXSMALL} color={Color.WHITE_70}>
										サインアウトするには、Metamaskから接続を解除して下さい。
									</Text>
								</StyledMetamaskGuideWrapper>
							)}
						</StyledProfileMenuContainer>
					)}
				</StyledSigninButtonWrapper>
			</StyledRightWrapper>
			{false && (
				<StyledWrongChainMessage>
					<Text fontType={FontType.SMALL_BOLD} color={Color.BG}>
						違うネットワークに接続しています。購入のためには、
						{process.env.NEXT_PUBLIC_ENV === "prod"
							? "Polygon"
							: "Mumbai Testnet"}
						に接続する必要があります。
					</Text>
					<StyledXWrapper>
						<X></X>
					</StyledXWrapper>
				</StyledWrongChainMessage>
			)}
		</StyledHeaderWrapper>
	);
};
