import styled from "@emotion/styled";
import tw from "twin.macro";

import { Color } from "../../../constants/Color";

export const StyledBackDrop = styled.div`
	${tw`fixed flex top-0 right-0 bottom-0 left-0 items-center justify-center z-70`}
	background-color: ${Color.BLACK_50};
`;

export const StyledModalContainer = styled.div`
	${tw`w-80 p-8 box-border bg-box-bg rounded-lg`}
`;

export const StyledModalHeader = styled.div`
	${tw`flex justify-between mb-2`}
`;

export const StyledCloseButton = styled.div`
	${tw`cursor-pointer hover:opacity-80`}
`;
