import { useContext } from "react";

import { AuthContext, AuthContextValueType } from "./AuthProvider";

export const useAuth = (): AuthContextValueType => {
	const {
		authStatus,
		userId,
		sub,
		user,
		isLoading,
		isSignInModalVisible,
		auth0SignIn,
		auth0SignOut,
		toggleSignInModal,
		refreshUser
	} = useContext(AuthContext);
	return {
		authStatus,
		userId,
		sub,
		user,
		isLoading,
		isSignInModalVisible,
		auth0SignIn,
		auth0SignOut,
		toggleSignInModal,
		refreshUser
	};
};
