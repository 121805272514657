import React from "react";

import { Color } from "../../../constants/Color";
import { FontType } from "../../../constants/Fonts";
import CloseX from "../../symbols/closeX.svg";
import { Text } from "../Text";
import {
	StyledBackDrop,
	StyledCloseButton,
	StyledModalContainer,
	StyledModalHeader
} from "./style";

export interface ErrorModalProps {
	message: string | null;
	onClose: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({ message, onClose }) => {
	return (
		<StyledBackDrop>
			<StyledModalContainer>
				<StyledModalHeader>
					<Text fontType={FontType.MAIN} color={Color.MAIN}>
						⚠️ エラー
					</Text>
					<StyledCloseButton onClick={onClose}>
						<CloseX width="17" height="17" />
					</StyledCloseButton>
				</StyledModalHeader>
				<Text fontType={FontType.SMALL}>{message}</Text>
			</StyledModalContainer>
		</StyledBackDrop>
	);
};
