import styled from "@emotion/styled";
import tw from "twin.macro";

import { Color } from "../../../constants/Color";

export const StyledHamburgerWrapper = styled.div`
	${tw`relative cursor-pointer`}
	width:22px;
	height: 22px;
`;

export const StyledTopBar = styled.div`
	width: 22px;
	height: 2px;
	background-color: ${Color.WHITE_100};
	position: absolute;
	top: 5px;
`;

export const StyledMiddleBar = styled.div`
	width: 22px;
	height: 2px;
	background-color: ${Color.WHITE_100};
	position: absolute;
	top: 11px;
`;

export const StyledBottomBar = styled.div`
	width: 22px;
	height: 2px;
	background-color: ${Color.WHITE_100};
	position: absolute;
	top: 17px;
`;
