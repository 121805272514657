import * as React from "react";

import { LoadingScreen } from "../../components/atoms/LoadingScreen";
import { useLoading } from "./hooks";

const LoadingIndicator: React.FC = () => {
	const { loadingIDs } = useLoading();

	return <>{loadingIDs.length > 0 && <LoadingScreen />}</>;
};

export default LoadingIndicator;
