export type ColorType = { __brand__: "ColorType" } & string;

type ColorObject = {
	MAIN: ColorType;
	BG: ColorType;
	BOX_BG: ColorType;
	SKELETON_1: ColorType;
	SKELETON_2: ColorType;
	BLACK_50: ColorType;
	WHITE_100: ColorType;
	WHITE_70: ColorType;
	WHITE_30: ColorType;
	WHITE_20: ColorType;
	WHITE_10: ColorType;
};

const Color: ColorObject = {
	MAIN: "#FFD337" as ColorType,
	BG: "#080808" as ColorType,
	BOX_BG: "#161616" as ColorType,
	SKELETON_1: "#202020" as ColorType,
	SKELETON_2: "#282828" as ColorType,
	BLACK_50: "rgba(0, 0, 0, 0.5)" as ColorType,
	WHITE_100: "#FFFFFF" as ColorType,
	WHITE_70: "rgba(255, 255, 255, 0.7)" as ColorType,
	WHITE_30: "rgba(255, 255, 255, 0.3)" as ColorType,
	WHITE_20: "rgba(255, 255, 255, 0.2)" as ColorType,
	WHITE_10: "rgba(255, 255, 255, 0.1)" as ColorType
};

export { Color as Color };
