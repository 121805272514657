import React, { ReactNode } from "react";
import { useState, useCallback, useEffect, useMemo } from "react";
import { apiClient } from "../../lib/apiClient";
import {
	MyNotification,
	Notification
} from "../../types/domain/Notification/Notification";
import { NotificationPayload, User } from "../../types/domain/User/User";
import { useAuth } from "../AuthProvider/hooks";

export type NotificationContextValueType = {
	artists: User[];
	myNotifications: MyNotification[];
	isUnreadExists: boolean;
	markAsRead: (notificationId: string) => void;
	refreshNotification: () => void;
};

export const NotificationContext = React.createContext(
	{} as NotificationContextValueType
);

const NotificationProvider: React.FC<{ children: ReactNode }> = ({
	children
}) => {
	const { userId, user, refreshUser } = useAuth();
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const notificationPayloads = useMemo<NotificationPayload[]>(
		() => user?.notificationPayloads ?? [],
		[user]
	);
	const [artists, setArtists] = useState<User[]>([]);

	const myNotifications = useMemo(() => {
		return notificationPayloads
			.filter((payload) => {
				const notification = notifications.find(
					(notification) => notification._id === payload.notificationId
				);
				return !!notification;
			})
			.map((payload) => {
				const notification = notifications.find(
					(notification) => notification._id === payload.notificationId
				)!;
				return {
					_id: notification._id,
					...(notification.userId && { userId: notification.userId }),
					...(notification.trackId && { trackId: notification.trackId }),
					title: notification.title,
					description: notification.description,
					sendAt: payload.sendAt,
					isRead: payload.isRead
				} as MyNotification;
			});
	}, [notifications, notificationPayloads]);

	useEffect(() => {
		if (myNotifications.length === 0) return;
		(async () => {
			const res = await apiClient.get(`/artists`);
			if (!res.data) return;
			setArtists(res.data.artists);
		})();
	}, [myNotifications]);

	useEffect(() => {
		fetchNotifications();
	}, [user, userId]);

	const fetchNotifications = useCallback(async () => {
		if (!userId || !user) return;
		const notificationIds = notificationPayloads.map(
			(payload) => payload.notificationId
		);
		console.log(notificationIds);
		const res = await apiClient.post(`/notifications`, {
			notificationIds
		});
		if (!res.data) {
			return;
		}
		setNotifications(res.data.notifications);
	}, [user, userId]);

	const markAsRead = useCallback(
		async (notificationId: string) => {
			if (!userId || notificationPayloads.length === 0) {
				return;
			}
			const index = notificationPayloads.findIndex(
				(payload) => payload.notificationId === notificationId
			);
			const payloads = notificationPayloads.slice();
			payloads[index].isRead = true;
			await apiClient.post(`/users/${userId}/notifications/${notificationId}`, {
				payloads
			});
			await refreshUser();
		},
		[userId, notificationPayloads]
	);

	const refreshNotification = useCallback(async () => {
		await fetchNotifications();
		await refreshUser();
	}, [fetchNotifications, refreshUser]);

	const isUnreadExists = useMemo(() => {
		return myNotifications.some(
			(notification) => notification.isRead === false
		);
	}, [myNotifications]);

	const contextValue = {
		artists,
		myNotifications,
		isUnreadExists,
		refreshNotification,
		markAsRead
	};

	return (
		<NotificationContext.Provider value={contextValue}>
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationProvider;
