import styled from "@emotion/styled";
import tw from "twin.macro";

import { ButtonType } from "../../../constants/Button";
import { Color } from "../../../constants/Color";

const getButtonStyle = (buttonType: ButtonType) => {
	switch (buttonType) {
		case ButtonType.NORMAL:
			return `
        background-color: transparent;
        transition: background-color 0.1s;
        &:hover {
          background-color: ${Color.BOX_BG}
        }
      `;
		case ButtonType.IMPORTANT:
			return `
          background-color: transparent;
          border: solid 1px ${Color.MAIN};
          color: ${Color.MAIN};
          transition: background-color 0.1s ease-out, color 0.1s ease-out;
          &:hover {
            background-color: ${Color.MAIN};
            color: ${Color.BG}
          }
        `;
	}
};

interface Props {
	buttonType: ButtonType;
	disabled: boolean;
}

export const StyledButton = styled.button<Props>`
	${tw`px-5 py-3 rounded-xl border-none ease-out flex items-center cursor-pointer`}
	${({ buttonType }) => getButtonStyle(buttonType)}
  ${({ disabled }) =>
		disabled &&
		`border: solid 1px ${Color.BOX_BG}; color:${Color.BOX_BG}; &:hover {
      background-color: transparent;
      color: ${Color.BOX_BG};
    }; cursor: default;`}
`;
