import { useContext } from "react";
import { NotificationContext } from "./NotificationProvider";

export const useNotification = () => {
	const {
		myNotifications,
		isUnreadExists,
		artists,
		refreshNotification,
		markAsRead
	} = useContext(NotificationContext);
	return {
		myNotifications,
		isUnreadExists,
		artists,
		refreshNotification,
		markAsRead
	};
};
