import { useRouter } from "next/router";
import { useContext } from "react";

import { useAuth } from "../../../providers/AuthProvider/hooks";
import { PlayerContext } from "../../../providers/PlayerProvider/PlayerProvider";

export const useSideMenu = () => {
	const router = useRouter();
	const { authStatus, userId } = useAuth();

	const pathname = router.pathname;
	const { track } = useContext(PlayerContext);

	return { pathname, track, authStatus, userId };
};
