import { useContext } from "react";

import { ErrorContext, ErrorContextValueType } from "./ErrorProvider";

export const useError = (): ErrorContextValueType => {
	const { error, setError, removeError } = useContext(ErrorContext);
	return {
		error,
		setError,
		removeError
	};
};
