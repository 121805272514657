import styled from "@emotion/styled";
import tw from "twin.macro";

import { Color } from "../../constants/Color";

export const StyledPlayerWrapper = styled.div`
	${tw`flex w-screen bg-bg h-18 fixed z-10 md:z-50 bottom-0 left-0`}
`;

export const StyledPlayerContainer = styled.div`
	${tw`flex w-screen sm:w-md md:w-lg lg:w-xl items-center`}
`;

export const StyledPlayWrapper = styled.div`
	${tw`flex items-center ml-6 sm:ml-12`}
`;

export const TrackImage = styled.img`
	${tw`w-10 h-10 rounded-md`}
`;

export const StyledTrackInfoWrapper = styled.div`
	gap: 2px;
`;

export const StyledPlayerLeftWrapper = styled.div`
	${tw`flex items-center w-36 sm:w-96`}
`;

export const StyledPlayerRightWrapper = styled.div`
	${tw`flex items-center gap-4`}
`;

export const StyledSeekBarWrapper = styled.div`
	${tw`absolute h-5 w-screen cursor-pointer`}
	top: -10px;
`;

export const StyledSeekBarBack = styled.div`
	${tw`absolute h-1 w-screen top-2 left-0`}
	background-color: ${Color.BOX_BG};
`;

export const StyledSeekBar = styled.div<{ rate: number }>`
	${tw`absolute h-1 top-2 left-0`}
	width: calc(100vw * ${({ rate }) => rate});
	background-color: ${Color.MAIN};
`;

export const StyledThumb = styled.div<{ rate: number }>`
	${tw`h-3 w-3 rounded-full absolute top-1`}
	left: calc(100vw * ${({ rate }) => rate} - 6px);
	background-color: ${Color.MAIN};
`;

export const StyledPlay = styled.div`
	${tw`cursor-pointer mt-1 mr-3`}
`;

export const StyledLink = styled.div``;
