import styled from "@emotion/styled";
import tw from "twin.macro";

import { Color } from "../../../constants/Color";

export const StyledBackDrop = styled.div`
	${tw`fixed flex top-0 right-0 bottom-0 left-0 items-center justify-center z-60`}
	background-color: ${Color.BLACK_50};
`;

export const StyledSvg = styled.svg`
	${tw`z-60`}
	height: 120px;
	width: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: rotation 0.5s linear infinite;
	@keyframes rotation {
		to {
			transform: rotate(360deg);
		}
	}
`;
