import Link from "next/link";
import React from "react";

import { Text } from "../../components/atoms/Text";
import Pause from "../../components/symbols/player_pause.svg";
import Play from "../../components/symbols/player_play.svg";
import { Color } from "../../constants/Color";
import { FontType } from "../../constants/Fonts";
import { usePlayer } from "./hooks";
import {
	StyledLink,
	StyledPlay,
	StyledPlayWrapper,
	StyledPlayerContainer,
	StyledPlayerLeftWrapper,
	StyledPlayerRightWrapper,
	StyledPlayerWrapper,
	StyledSeekBar,
	StyledSeekBarBack,
	StyledSeekBarWrapper,
	StyledThumb,
	StyledTrackInfoWrapper,
	TrackImage
} from "./style";

export const Player: React.FC = ({}) => {
	const { isPlaying, track, current, togglePlay, changeSeek } = usePlayer();

	return (
		<>
			{track && (
				<StyledPlayerWrapper>
					<StyledPlayerContainer>
						{
							<StyledSeekBarWrapper
								onClick={(e) => changeSeek(e.clientX / window.innerWidth)}
							>
								<StyledSeekBarBack />
								<StyledSeekBar rate={current / track.duration} />
								<StyledThumb rate={current / track.duration} />
							</StyledSeekBarWrapper>
						}
						<StyledPlayerLeftWrapper>
							<StyledPlayWrapper onClick={() => togglePlay(track)}>
								<StyledPlay>
									{" "}
									{isPlaying ? (
										<Pause width={22} height={22} fill={Color.WHITE_100} />
									) : (
										<Play width={22} height={22} fill={Color.WHITE_100} />
									)}
								</StyledPlay>
								<Text fontType={FontType.EXSMALL} color={Color.WHITE_70}>
									{current &&
										`${Math.floor(Math.floor(current) / 60)}:${String(
											Math.floor(current) % 60
										).padStart(2, "0")}`}
									/
									{`${Math.floor(Math.floor(track.duration) / 60)}:${String(
										Math.floor(track.duration) % 60
									).padStart(2, "0")}`}
								</Text>
							</StyledPlayWrapper>
						</StyledPlayerLeftWrapper>
						<StyledPlayerRightWrapper>
							<TrackImage
								src={`https://storage.googleapis.com/cypher-test-cover-image/${track.imageHash}`}
							/>
							<StyledTrackInfoWrapper>
								<Link href={`/track/${track._id}`}>
									<StyledLink>
										<Text fontType={FontType.SMALL} reactive>
											{track.title}
										</Text>
									</StyledLink>
								</Link>
								<Text fontType={FontType.EXSMALL} color={Color.WHITE_70}>
									{track.displayName}
								</Text>
							</StyledTrackInfoWrapper>
						</StyledPlayerRightWrapper>
					</StyledPlayerContainer>
				</StyledPlayerWrapper>
			)}
		</>
	);
};
