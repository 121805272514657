import styled from "@emotion/styled";
import tw from "twin.macro";

export const StyledAppWrapper = styled.div`
	${tw`relative`}
`;

export const StyledMainPanel = styled.div<{
	isPlayerVisible: boolean;
	isSideMenuPresent: boolean;
	isLg: boolean;
}>`
	${tw`w-full flex justify-center relative box-border`}
	padding-left: 320px;
	padding-right: 24px;
	padding-top: 80px;
	margin-bottom: ${({ isPlayerVisible }) => (isPlayerVisible ? "72px" : "0")};
	transition: padding-left 0.05s ease-out;
	${({ isSideMenuPresent, isLg }) =>
		(!isSideMenuPresent || !isLg) && "padding-left:24px;"}
`;

export const StyledContainer = styled.div`
	${tw`w-full sm:w-md md:w-lg lg:w-lg xl:w-xl pt-3 pb-8`};
	box-sizing: border-box;
`;
